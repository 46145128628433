<template>
<div>

  <v-card>
    <v-card-title>
      {{titulo}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="fas fa-search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
  
    <v-data-table
      :headers="headers"
      :items="customers"
      :search="search"
      sort-by="name"
      class="elevation-1"
      :loading="loading"
      loading-text="Consultando datos... Espere por favor"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-spacer></v-spacer>

          <v-dialog v-model="dialog" persistent max-width="700px">
            <template v-slot:activator="{ on }">
                <v-btn ref="boton2" color="primary" dark v-on="on">Nuevo Registro</v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row>
                      <!-- Poner mascara -->

                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.rtn"
                          v-mask="'####-####-######'"
                          label="RTN"
                          :rules="editedItem.rtnRules"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.name"
                          label="Nombre Comercial"
                          :rules="editedItem.nameRules"
                          autocomplete="new-name"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.agent"
                          label="Representante Legal"
                          :rules="editedItem.agentRules"
                          autocomplete="new-agent"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          v-model="editedItem.phone"
                          v-mask="'####-####'"
                          label="Teléfono"
                          :rules="editedItem.phoneRules"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="6">
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                              v-model="editedItem.license_expiration"
                              label="Vencimiento Licencia"
                              prepend-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              readonly
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="editedItem.license_expiration"
                            no-title
                            @input="menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          solo
                          auto-grow
                          outlined
                          rows="3"
                          v-model="editedItem.home_address"
                          label="Dirección"
                          :rules="editedItem.addressRules"
                        ></v-textarea>
                      </v-col>

                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogDelete" max-width="450">
            <v-card>
              <v-card-title>
                <span>¿Desea eliminar este cliente?</span>
                <v-spacer></v-spacer>
              </v-card-title>
              <v-card-text>
                <div> <b>ID:</b> {{ editedItem.identity }} </div>
                <div> <b>RTN:</b> {{ editedItem.rtn }} </div>
                <div> <b>Nombres:</b> {{ editedItem.name }} </div>
                <div> <b>Representante Legal:</b> {{ editedItem.agent }} </div>
              </v-card-text>
              <v-card-actions>

                <v-row no-gutters style="flex-wrap: nowrap;">
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  large
                  rounded
                  outlined
                  color="warning"
                  @click="close"
                  >Cancelar</v-btn
                >
                <v-btn
                  class="flex-grow-1 flex-shrink-0"
                  large
                  rounded
                  color="error"
                  @click="eliminar"
                  >Aceptar</v-btn
                >
              </v-row>

              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogUpload" max-width="650">
            <v-card>
              <v-card-title>
                <span>Subir documentos de {{editedItem.name}}</span>
                <v-spacer></v-spacer>
              </v-card-title>

              <v-card-text>
                <template>
                  <v-file-input
                    v-model="files"
                    color="deep-purple accent-4"
                    counter
                    :label="path_label"
                    placeholder="Selecciona el archivo"
                    prepend-icon="mdi-paperclip"
                    accept="application/pdf"
                    outlined
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>
                </template>
              </v-card-text>

              <v-card-actions>

                <v-row no-gutters style="flex-wrap: nowrap;">
                  <v-btn
                    class="flex-grow-1 flex-shrink-0"
                    large
                    rounded
                    outlined
                    color="gray"
                    @click="close"
                  >Cancelar</v-btn>
                  <v-btn
                    class="flex-grow-1 flex-shrink-0 white--text"
                    large
                    rounded
                    color="deep-purple"
                    @click="upload"
                    >Subir</v-btn>
                </v-row>

              </v-card-actions>
            </v-card>
          </v-dialog>


          <v-dialog 
          v-model="dependentList" 
          max-width="700px"
          >
            <!-- <template v-slot:activator="{ on }">
                <v-btn ref="boton2" color="primary" dark v-on="on">Nuevo Registro</v-btn>
            </template> -->
            <v-card>
              
              <v-card-title>
                <v-col cols="12" sm="12" md="4">
                <span class="headline">Dependientes</span>
                </v-col>
                  
                <v-spacer></v-spacer>
                <div>

              <v-btn
                style="right:10px; top:10px;"
                icon
                color="error"
                fab
                absolute
                @click="dependentList = false"
              >
                <v-icon dark>fa-times</v-icon>
              </v-btn>

                </div>
              </v-card-title>

              <v-card-text>
                <v-container>

                  <v-data-table
                    :headers="headers_dependents"
                    :items="dependents"
                    sort-by="date"
                    sort-desc
                    :loading="loading"
                    loading-text="Consultando datos... Espere por favor"
                  >
                    <template v-slot:top>
                      <v-toolbar flat color="white">
                        <v-spacer></v-spacer>

                        <v-dialog v-model="dependentDialog" max-width="400px">
                          <template v-slot:activator="{ on }">
                              <v-btn ref="boton2" color="primary" dark v-on="on">Nuevo Registro</v-btn>
                          </template>
                          <v-card>
                            <v-card-title>
                              <span class="headline">{{ formTitle2 }}</span>
                            </v-card-title>

                            <v-card-text>
                              <v-container>
                                <v-form ref="form2" v-model="valid">
                                  <v-row>
                                    <v-col cols="12" sm="12" md="12">
                                      <v-text-field
                                        v-model="editedItem2.name"
                                        label="Nombre"
                                        :rules="editedItem2.nameRules"
                                        required
                                      ></v-text-field>
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" text @click="closeDependent">Cancelar</v-btn>
                              <v-btn color="blue darken-1" text @click="saveDependent">Guardar</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>

                        <v-dialog v-model="dependentDialogDelete" max-width="450">
                        <v-card>
                          <v-card-title>
                            <span>¿Desea eliminar a {{editedItem2.name}}?</span>
                            <v-spacer></v-spacer>
                          </v-card-title>

                          <v-card-actions>
                            <v-btn color="green darken-1" text @click="closeDependent">Cancelar</v-btn>
                            <v-btn color="red darken-1" text @click="delete2">Aceptar</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      </v-toolbar>
                    </template>

                    <template v-slot:[`item.action`]="{ item }">
                      <v-icon small class="mr-2" @click="editItemDependent(item)">fas fa-edit</v-icon>
                      <v-icon small @click="deleteItemDependent(item)">fas fa-trash-alt</v-icon>
                    </template>

                  </v-data-table>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog>


        </v-toolbar>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <!-- <v-tooltip top color="purple">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="blue darken-3" v-bind="attrs" v-on="on" class="mr-3" @click="insured(item)">fas fa-person-circle-plus</v-icon>
          </template>
          <span>Asegurados</span>
        </v-tooltip>
        <v-tooltip top color="purple">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="pink" v-bind="attrs" v-on="on" class="mr-3" @click="dependent(item)">fas fa-children</v-icon>
          </template>
          <span>Dependientes</span>
        </v-tooltip> -->
        <v-tooltip top color="purple">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="mr-3" @click="uploadFile(item)">fas fa-file-upload</v-icon>
          </template>
          <span>Documentos</span>
        </v-tooltip>
        <v-icon color="green" class="mr-3" @click="editItem(item)">fas fa-edit</v-icon>
        <v-icon color="red" v-if="master==1" @click="deleteItem(item)">fas fa-trash-alt</v-icon>
      </template>
      <template v-slot:[`item.path`]="{ item }">
        <v-icon 
        v-if="item.path"
        color="purple darken-3"
        @click="seeDocument(item)"
        >fas fa-file-pdf</v-icon>
      </template>

    </v-data-table>
  </v-card>
</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import * as moment from 'moment';
import jwt_decode from "jwt-decode";

export default {
  name: "clientes",
  data: () => ({
    dialog: false,
    master: jwt_decode(localStorage.token).master || '',
    customers: [],
    files: [],
    dependents: [],
    dialogDelete: false,
    dependentDialogDelete: false,
    dialogUpload: false,
    dependentDialog: false,
    dependentList: false,
    insuredList: false,
    search: "",
    loading: "",
    titulo: "Persona Juridica",
    id_customer: null,
    activePicker: null,
    date: moment().subtract(18, 'years').format('YYYY'),
    menu: false,
    headers: [
      { text: "Nombre Comercial", align: "left", value: "name" },
      { text: "Representante Legal", align: "left", value: "agent" },
      { text: "RTN", align: "left", sortable: false, value: "rtn" },
      { text: "Teléfono", align: "left", sortable: false, value: "phone" },
      { text: "Dirección", sortable: false, width:"600", value: "home_address" },
      { text: "Documento", sortable: false, value: "path" },
      { text: "Acciones", value: "action", width:"120", sortable: false }
    ],
    civil: [
      { id: 1, name: "Casado" },
      { id: 2, name: "Unión Libre" },
      { id: 3, name: "Soltero" },
      { id: 4, name: "Separado" },
      { id: 5, name: "Divorciado" },
      { id: 6, name: "Viudo" },
    ],
    headers_dependents: [
      { text: "Nombre", align: "left", value: "name" },
      { text: "Acciones", align: "center", value: "action", sortable: false }
    ],
    editedIndex: -1,
    editedIndex2: -1,

    valid: true,
    editedItem: {
      id: "",
      rtn: "",
      identity: "",
      name: "",
      agent: "",
      age: "",
      status: "",
      name_wife: "",
      phone: "",
      license_expiration: "",
      email: "",
      profession: "",
      workplace: "",
      home_address: "",
      work_address: "",

      nameRules: [v => !!v || "Escribe los nombres"],
      agentRules: [v => !!v || "Escribe el representante Legal"],
      statusRules: [v => !!v || "Escoge el estado civil"],
      wifeRules: [v => !!v || "Escribe el nombre de su cónyugue"],
      professionRules: [v => !!v || "Escribe la profesión"],
      workplaceRules: [v => !!v || "Escribe el lugar de trabajo"],
      addressRules: [v => !!v || "Escribe la dirección"],
      work_addressRules: [v => !!v || "Escribe la dirección de trabajo"],

      identityRules: [
                      v => !!v || "Escribe la identidad",
                      v => /^\d{4}-\d{4}-\d{5}$/.test(v) || "Escribe correctamente la Identidad"
                     ],
      rtnRules: [
                      v => !!v || "Escribe el RTN",
                      v => /^\d{4}-\d{4}-\d{6}$/.test(v) || "Escribe correctamente el RTN"
                ],
      phoneRules: [
                    v => !!v || "Escribe el teléfono",
                    v => /^(2|3|9|8)\d{3}-\d{4}$/.test(v) || "El teléfono debe ser valido"
                  ],
      emailRules: [
                    v => !!v || 'Escribe un correo electrónico',
                    v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 
                    'El correo electrónico debe ser valido',
                  ],
      active: false
    },

    defaultItem: {
      rtn: "",
      identity: "",
      name: "",
      agent: "",
      age: "",
      status: "",
      name_wife: "",
      phone: "",
      license_expiration: "",
      email: "",
      profession: "",
      workplace: "",
      home_address: "",
      work_address: "",

      nameRules: [v => !!v || "Escribe los nombres"],
      agentRules: [v => !!v || "Escribe el representante Legal"],
      statusRules: [v => !!v || "Escoge el estado civil"],
      wifeRules: [v => !!v || "Escribe el nombre de su cónyugue"],
      professionRules: [v => !!v || "Escribe la profesión"],
      workplaceRules: [v => !!v || "Escribe el lugar de trabajo"],
      addressRules: [v => !!v || "Escribe la dirección"],
      work_addressRules: [v => !!v || "Escribe la dirección de trabajo"],

      identityRules: [
                      v => !!v || "Escribe la identidad",
                      v => /^\d{4}-\d{4}-\d{5}$/.test(v) || "Escribe correctamente la Identidad"
                     ],
      rtnRules: [
                      v => !!v || "Escribe el RTN",
                      v => /^\d{4}-\d{4}-\d{6}$/.test(v) || "Escribe correctamente el RTN"
                ],
      phoneRules: [
                    v => !!v || "Escribe el teléfono",
                    v => /^(2|3|9|8)\d{3}-\d{4}$/.test(v) || "El teléfono debe ser valido"
                  ],
      emailRules: [
                    v => !!v || 'Escribe un correo electrónico',
                    v => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 
                    'El correo electrónico debe ser valido',
                  ],
      active: false
    },

    editedItem2: {
      id: "",
      name: "",
      nameRules: [v => !!v || "Escribe el nombre"],
    },

    defaultItem2: {
      name: "",
      nameRules: [v => !!v || "Escribe el nombre"],
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Registro" : "Editar Registro";
    },
    formTitle2() {
      return this.editedIndex2 === -1 ? "Nuevo Registro" : "Editar Registro";
    },
    maxDate(){
      return moment().subtract(18, 'years').format('YYYY-MM-DD');
    },
    path_label(){
      return this.editedItem.filename;
    },
    ...mapState(["db","headers_db"]),
  },

  watch: {
    menu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    dialog(val) {
      val || this.close();
    }
  },

  methods: {
    ...mapActions(["cerrarSesion", "validateSession"]),

    // Metodos base de datos
    async getCustomers(){

      await axios.get(this.db + 'customers/1',
        {
          headers: this.headers_db
        }).then(response => {
          this.customers = response.data.data;
          this.loading = false;
          console.log(response.data.data);
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getCustomers(); })
        });
    },

    addCustomer(payload){
      console.log(payload);
      axios.post(this.db + 'customer/add', payload,
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        this.getCustomers();
      })
      .catch((error) => {
        this.validateSession(error).then((resp) => { if(resp) this.addCustomer(payload); })
      });

    },

    updateCustomer(payload){

      axios.post(this.db + 'customer/update', payload,
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response.data.message);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        //this.getCustomers();
      })
      .catch((error) => {
        this.validateSession(error).then((resp) => { if(resp) this.updateCustomer(payload); })
      });

    },

    deleteCustomer(id){

      axios.post(this.db + 'customer/delete', { id },
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.customers = this.customers.filter(doc => {
        return doc.id != id
        })
      })
      .catch((error) => {
        this.validateSession(error).then((resp) => { if(resp) this.deleteCustomer(id); })
      });

    },

    async upload() {
      
      if (this.files.size > 0) {
          let formData = new FormData();
          
          // files
          formData.append('files', this.files);
          formData.append('name', this.files.name);
          formData.append('id', this.editedItem.id);

          await axios.post(this.db + "customer/upload", formData,
          {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'BEARER ' + localStorage.token
            }
          })
          .then(response => {
              this.$store.dispatch("setSnackbar", {
                text: 'Documento subido con éxito',
              });
              this.getCustomers();
              console.log({ response });
          })
          .catch(error => {
              this.validateSession(error).then((resp) => { if(resp) this.upload(); })
          });
      } else {
          console.log("there are no files.");
      }
      this.dialogUpload = false;
    },

    async seeDocument(item){
      await axios.get(this.db + 'customer/download/' + item.path,
      {
        responseType: 'arraybuffer',
        headers: {
            // 'Content-Type': 'multipart/form-data',
            "Content-type": "application/x-www-form-urlencoded",
            'Authorization': 'BEARER ' + localStorage.token
        }
      })
      .then(response => {
          console.log(response );
          let blob = new Blob([response.data], { type: 'application/pdf' } ),
          url = window.URL.createObjectURL(blob)

          window.open(url);
      })
      .catch(error => {
          console.log( error );
          if (error.response.status == 401) {
            this.getCustomers();
          }
      });
    },

    // DEPENDENTS

    async getDependents(){

      await axios.get(this.db + 'dependents/' + this.id_customer,
        {
          headers: this.headers_db
        }).then(response => {
          this.dependents = response.data.data;
          this.loading = false;
          console.log(response.data.data);
        })
        .catch((error) => {
          this.dependents = [];
          this.validateSession(error).then((resp) => { if(resp) this.getDependents(); })
        });
    },

    addDependent(payload){
      axios.post(this.db + 'dependent/add', payload,
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        this.getDependents();
      })
      .catch((error) => {
        this.validateSession(error).then((resp) => { if(resp) this.addDependent(payload); })
      });

    },

    updateDependent(payload){

      axios.post(this.db + 'dependent/update', payload,
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response.data.message);
        this.$store.dispatch("setSnackbar", {
          text: response.data.message,
        });
        //this.getCustomers();
      })
      .catch((error) => {
        this.validateSession(error).then((resp) => { if(resp) this.updateDependent(payload); })
      });

    },

    deleteDependent(id){

      axios.post(this.db + 'dependent/delete', { id },
      {
        headers: this.headers_db
      })
      .then((response) => {
        console.log(response);
        this.dependents = this.dependents.filter(doc => {
        return doc.id != id
        })
      })
      .catch((error) => {
        this.validateSession(error).then((resp) => { if(resp) this.deleteDependent(id); })
      });

    },

    // FIN Metodos base de datos


    // Metodos Generales

    saveDate(date) {
      let age = moment().diff(moment(date), 'years');
      // console.log((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10));
      this.editedItem.age = age;
      this.editedItem.birthday = date;
      this.$refs.menu.save(date);
    },

    editItem(item) {
      // this.$refs.form.resetValidation();
      if (item.status > 0) {
        item.status = this.civil.filter(doc => {
          return doc.id == item.status
        })[0];
      }
      
      this.editedIndex = this.customers.indexOf(item);
      this.dialog = true;
      this.editedItem = Object.assign({}, item);
    },

    deleteItem(item) {
      console.log(item.id);

      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    editItemDependent(item) {
      console.log(item);
      this.editedIndex2 = this.dependents.indexOf(item);
      this.dependentDialog = true;
      this.editedItem2 = Object.assign({}, item);
    },

    deleteItemDependent(item) {
      console.log(item.id);

      this.editedItem2 = Object.assign({}, item);
      this.dependentDialogDelete = true;
    },

    uploadFile(item){
      console.log(item);
      // this.files.push(item.path);
      this.editedItem = Object.assign({}, item);
      this.dialogUpload = true;
    },

    close() {
      this.dialog = false;
      this.dialogDelete = false;
      this.dialogUpload = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      console.log(this.$refs.form.validate());
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          Object.assign(this.customers[this.editedIndex], this.editedItem);
          this.editedItem.status = this.editedItem.status.id;
          this.updateCustomer(this.editedItem);
        } else {
          this.customers.push(this.editedItem);
          this.editedItem.status = this.editedItem.status.id;
          this.editedItem.customer_type = 1;
          this.addCustomer(this.editedItem);
        }
        this.close();
      }
    },

    eliminar() {
      this.deleteCustomer(this.editedItem.id);
      this.close();
    },

    // Dependientes
    dependent(item) {
      console.log(item.id);
      this.id_customer = item.id;
      this.getDependents();
      this.dependentList = true;
    },

    // Asegurados
    insured(item) {
      console.log(item.id);
      this.id_customer = item.id;
      // this.getDependents();
      this.insuredList = true;
    },

    closeDependent() {
      
      this.dependentDialog = false;
      this.dependentDialogDelete = false;
      setTimeout(() => {
        this.editedItem2 = Object.assign({}, this.defaultItem2);
        this.editedIndex2 = -1;
      }, 300);
    },

    saveDependent() {
      if (this.$refs.form2.validate()) {
        if (this.editedIndex2 > -1) {
          Object.assign(this.dependents[this.editedIndex2], this.editedItem2);
          this.updateDependent(this.editedItem2);
        } else {
          this.dependents.push(this.editedItem2);
          this.editedItem2.id_customer = this.id_customer;
          this.addDependent(this.editedItem2);
        }
        this.closeDependent();
      }
    },

    delete2() {
      this.deleteDependent(this.editedItem2.id);
      this.closeDependent();
    },
  },
  created() {
    // this.date = moment('2004-01-01');
    this.getCustomers();
  }
};
</script>

